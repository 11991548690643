import React from "react"
import Helmet from "react-helmet"

const NotFoundPage = () => (
  <Helmet>
    <meta httpEquiv="refresh" content="0; URL='/'" />
  </Helmet>
)

export default NotFoundPage
